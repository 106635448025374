function App() {
  return (
    <div className="m-8 sm:m-8 flex flex-col gap-y-8">
      <div className="flex flex-col gap-y-2">
        <h1 className="text-3xl font-bold text-blue-600">OmniConvert</h1>
        <p>
          OmniConvert is a powerful unit and currency converter focused on functionality, usability, and speed. It is
          completely free, supports a wide variety of conversion categories, and works offline. Exchange rates are
          updated in real-time (when connected to the internet) and all 166 major world currencies are supported. There
          are no conversion limits or size restrictions, and it even comes with a dark mode!
        </p>
        <p>
          OmniConvert offers an additional selection of useful calculators (e.g. salary, gratuity, baking) along with
          compilations of common scientific constants relevant to fields like physics and chemistry.
        </p>
        <b className="-mb-3">Conversions</b>
        <p>Currency, Volume, Mass, Temperature, Time, Length, Speed, Gas, Area, Energy, Pressure, Torque, Data</p>
        <b className="-mb-3">Calculations</b>
        <p>Baking, Salary, Tip, Percentage</p>
        <b className="-mb-3">Constants</b>
        <p>Chemistry, Physics, Density, Unit Prefixes</p>
      </div>
      <div className="flex flex-col gap-y-2">
        <h2 className="text-xl font-bold">Privacy Policy</h2>
        <p>We don’t store your data. Period.</p>
      </div>
      <div className="flex flex-col gap-y-2">
        <h2 className="text-xl font-bold">Support</h2>
        <p>
          Do you have any feedback for OmniConvert? Email me at{" "}
          <a className="text-blue-600" href="mailto:jonathanchen.dev@gmail.com">
            jonathanchen.dev@gmail.com!
          </a>
        </p>
      </div>
    </div>
  );
}

export default App;
